;(function($, window, document, undefined) {
  var $win = $(window);
  var $doc = $(document);
  var $winW = $win.width();
  var isMobile = $winW < 769;

  var map;

  function initMap(arg) {
    var input = document.getElementById(arg);
    var mapLat = parseFloat(input.getAttribute('data-lat'));
    var mapLng = parseFloat(input.getAttribute('data-lng'));

    var inputLocation = new google.maps.LatLng(mapLat , mapLng);

    var map = new google.maps.Map(input, {
      zoom: 16,
      center: inputLocation,
      disableDefaultUI: true
    });

    var marker = new google.maps.Marker({
      position: inputLocation,
      map: map
    });

    google.maps.event.addDomListener(window, "resize", function() {
      var center = map.getCenter();
      google.maps.event.trigger(map, "resize");
      map.setCenter(center);
    });
  }

  $.fn.equalizeHeight = function() {
    var maxHeight = 0, itemHeight;

    for (var i = 0; i < this.length; i++) {
      $(this[i]).css('height', 'auto');

      itemHeight = $(this[i]).outerHeight();

      if (maxHeight < itemHeight) {
        maxHeight = itemHeight;
      }
    }

    return (!isMobile) ? this.height(maxHeight) : $(this[i]).css('height', 'auto');
  };

  $(window).bind("load", function() {
    $('.sub-page-repeater').each(function() {
      $(this).equalizeHeight();
    });
  });

  $doc.ready(function() {
    $('.js-link-more').on('click', function(event) {
      event.preventDefault();

      $(this)
          .closest('.article')
          .toggleClass('content-expanded');
    });

    // Map
    $map = $('#map');
    if ($map.length > 0) {
      initMap('map');
    }

    // Popup

    $('.link-popup').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      mainClass: 'mfp-img-mobile',
      image: {
        verticalFit: true
      }

    });

    // Slider
    $('.owl-carousel').owlCarousel({
      items: 1,
      dots: false,
      nav: true
    });

    // Photos

    $('.list-thumbs a').on('click', function(event) {
      event.preventDefault();

      var ix = $(this).data('index');

      $('.list-images')
          .find('li')
          .eq(ix)
          .addClass('current')
          .siblings()
          .removeClass('current');
    });

    // Accordion

    $('.accordion-head').on('click', function(event) {
      $(this)
          .closest('.accordion-section')
          .toggleClass('accordion-expanded')
          .siblings()
          .removeClass('accordion-expanded');
    });

    // Mobile Menu

    $('.btn-menu').on('click',function(event) {
      event.preventDefault();

      $(this).toggleClass('active');
      $(this)
          .closest('.header')
          .toggleClass('nav-expanded');
    });

    $.fn.doPlaceholders = function() {
      if ($.support.placeholder) {
        return this;
      }

      var $fields = this.filter(function () {
        // Don't re-initialize
        return !$(this).data('didPlaceholders');
      });

      $fields.on('focus blur', function(event) {
        var placeholder = this.getAttribute('placeholder');

        if (event.type === 'focus' && placeholder === this.value) {
          this.value = '';
        } else if (event.type === 'blur' && this.value === '') {
          this.value = placeholder;
        }
      });

      // Set the initial value to the title
      $fields.each(function() {
        if (this.value === '') {
          this.value = this.getAttribute('placeholder');
        }
      });

      // Mark the fields as initialized
      $fields.data('didPlaceholders', true);

      // Alllow
      return $fields;
    };

    $.support.placeholder = (function() {
      return 'placeholder' in document.createElement('input');
    })();

    /* Don't select elements if placeholder is natively supported*/
    if (!$.support.placeholder) {
      $('input[placeholder], textarea[placeholder]').doPlaceholders();
    }
  });

  $win.on('load resize', function() {
    $winW = $win.width();
    isMobile = $winW < 769;

    if (!isMobile) {
      $('.address').equalizeHeight();
      $('.sub-page-repeater').equalizeHeight();
    }

  }).on('scroll', function() {
    var winT = $win.scrollTop();
    $('.header').toggleClass('fixed', winT > 0);
  });

  $('.gform_footer input').addClass('subscribe-btn btn btn-white');

  // Google Analytics goal tracking
  
  // Donation
  //ga('send', 'event', 'Donation', 'Submission');
  
})(jQuery, window, document);

